import React, { Component, Fragment } from 'react';
import "./add-user/add-user.less";

class UserStatusConfirmationPopup extends Component {
  constructor(props) {
    super(props);
    this.state = {

    }
  }

  render() {
    return (
      <div className="add-user-wrap">
        <div className="overlay"></div>
        <div id="modal" className="modal-popupUserConfirmation">
          <div className="content add-edit-user">
            <div>
              {this.props.displayText}
            </div>
            <div className='wt-100p flex justify-center mt-30'>
              <button className="add-button" 
              onClick={this.props.onConfirmUserStatusChange}
              >
                Yes
              </button>
              <button
                className="add-button discard"
                onClick={this.props.onCancelUserStatusChange}
              >
                No
              </button>
            </div>
          </div>
        </div>
      </div>
    );
  }
}


export default UserStatusConfirmationPopup;


