import React, { Component, Fragment } from 'react';
import Select from 'react-select';
import '../update-plants-depots';
import Delete from "../../../../assets/images/delete.svg";
import Dropup from "../../../../assets/images/drop-up-arrow.svg";
import SelectBranches from "../../contracts_management/select-branches/select-branches";
import InputText from '../../../common/components/input-text/input-text';
import { getCookie } from '../../../../utils/cookies';
import {  isAWL,isBeams } from '../../../../utils/common';
import ACTIONS from "../../../common/action_constant"
import { connect } from "react-redux";
import { withRouter } from 'react-router-dom';
const APPROVAL_MODE = [{id: 1, label: 'Flat', value: 1}, {id: 2, label: 'Hierarchy', value: 2}];

class ApprovalMatrix extends Component {
    constructor(props) {
        super(props); 
        this.state = {
            approval_matrix: [],
            approverTypes: [],
            emails: [],
            deptEmails: [],
            approver_values: [],
            applyToOtherBranches: false,
            company_id: props.companyId,
            branch_id: props.branchId,
            approval_mode: 1,
            selApproverType: {},
            depts: [],
            checkDropDown: false,
            showSection: [],
            destination: [],
            dest_region:'',
            isCsd:'',
            isSlabSelected: false,
            accountsChargeError : "",
            accountsEmailError : "",
            errors:{},
            selectedCharges: []
        }
    }

    componentDidMount () {
        this.getIPaddress()
        this.props.getApproverTypes();
        this.props.getEmailList();
        this.props.getApproverList();
        this.props.getDepartmentsEmailList();
        this.props.getLOBList();
        this.getCitiesList();
        this.props.getAllChargesForApproval();
        this.getRegionMasterDropdowns();
        this.props.getSlabRange();
    }

    getRegionMasterDropdowns = () => {
      const { dispatch } = this.props;
      dispatch({
        type: ACTIONS.PLANTS.GET_REGION_DROPDOWN,
        param: {
          // company_id:getUserCompanyId()
        }
      })
      }

  getIPaddress = () => {
    fetch('https://geolocation-db.com/json/')
      .then(response => response.json())
      .then(data => this.setState({IP:data.IPv4}))
      .catch(error => console.log(error))
  }

    getEmailList = (emailSearch) => {
      this.props.getEmailList(emailSearch);
    }

    getCitiesList = (citySearch) => {
      const { company_id } = this.props.approverMatrix;
      const data = {
        company_id: company_id || this.state.company_id,
      }
      if(citySearch){
        data.search = citySearch;
      }
      this.props.getCitiesList(data)
    }

    UNSAFE_componentWillReceiveProps(nextProps) {
        if(nextProps.emailList && nextProps.emailList.length > 0) {
           const emails = [];
            (nextProps.emailList || []).forEach(email => {
                emails.push({
                    label: email,
                    value: email
                })
            })
            this.setState({ emails });
        }
        if(nextProps.deptEmailList && nextProps.deptEmailList.length > 0) {
           const depts = [];
            (nextProps.deptEmailList || []).forEach(dept => {
                depts.push({
                    ...dept,
                    label: dept.department_name,
                    value: dept.department_id
                })
            })
            this.setState({ depts });
        }
        if(nextProps.emailIDList && nextProps.emailIDList.length > 0) {
          const emailIDs = [];
           (nextProps.emailIDList || []).forEach(email => {
            emailIDs.push({
                   label: email.user_email,
                   value: email.user_id
               })
           })
           this.setState({ emailIDs });
       }
        if(nextProps.approverMatrix && 
         Object.keys(nextProps.approverMatrix).length > 0 && 
         nextProps.approverMatrix.approval_matrix && (JSON.stringify(nextProps.approverMatrix.approval_matrix) !== JSON.stringify(this.state.approval_matrix))
        ) {
           this.setState({ 
              approval_matrix: nextProps.approverMatrix.approval_matrix,
              company_id: nextProps.companyId,
              branch_id: nextProps.branchId
            });
        } else if(nextProps.approverMatrix && !Object.keys(nextProps.approverMatrix).length) {
           this.setState({ 
              approval_matrix: [],
               company_id: nextProps.companyId,
               branch_id: nextProps.branchId
            });
        }
    }

    validationAccountChargeDetails = (type,deletedEmails,deletedLevel,appIndex) =>{
      const { approval_matrix, approval_mode, IP } = this.state;
      const accountsData = approval_matrix[appIndex].data_matrix
      if(type == 24){
        for (var i in accountsData) {
          if (accountsData[i].charges == "" || Array.isArray(accountsData[i].charges) && accountsData[i].charges.length <= 0 ) {
            this.setState({ accountsChargeError: "Please Select the charges" });
            return false;
          }
          const temlLevel = accountsData[i].data_matrix
          if(accountsData[i].data_matrix){
          for (var i in temlLevel){
             if (temlLevel[i].emails == "" || Array.isArray(temlLevel[i].emails) && temlLevel[i].emails.length <= 0) {
              this.setState({ accountsChargeError: "Please Select the approver email" });
              return false;
            }
          }
        }
        }
      }
      this.setState({accountsEmailError : "", accountsChargeError : ""})
      return true
    }

    updateApproverList = (type,deletedEmails,deletedLevel,appIndex) => {
       const { approval_matrix, approval_mode, IP } = this.state;
       const { company_id, branch_id, setting_id } = this.props.approverMatrix;
       const cookieData = getCookie('teg-user') || getCookie('user');
       const user = cookieData ? JSON.parse(cookieData) : {};
       let matrix = {}
       if (this.validationAccountChargeDetails(type,deletedEmails,deletedLevel,appIndex)) {
       if (type) {
        matrix = approval_matrix.find(obj => obj.approver_type===type);
       }
       if(type === 1 && matrix && matrix.data_matrix && matrix.data_matrix.length > 0) {
          if(matrix.data_matrix[0]['levels'].length > 0) {
          const filteredData = (matrix.data_matrix[0]['levels'] || []).filter((levelData, levelInd) =>  levelData['emails'].length > 0 );
          matrix.data_matrix[0]['levels'] = (filteredData || []).map((levelEmail, index) => {
              levelEmail['level'] = index + 1;
              return levelEmail;
          })
          matrix.data_matrix[0]['branch_code'] = this.props.plantsDepots.currentDepot.branch_code; // Include branch_code
          } else {
            matrix = []
          }
        }
        if (matrix && matrix.data_matrix && matrix.data_matrix.length > 0) {
          matrix.data_matrix.forEach(obj => {
            obj['branch_code'] = this.props.plantsDepots.currentDepot.branch_code;
          });
        }
       const data = {
         company_id: company_id || this.state.company_id,
         branch_id: branch_id || this.state.branch_id,
         approval_mode,
         approver_type: type,
         approval_matrix: matrix && matrix.data_matrix || [],
         setting_id: matrix && matrix.setting_id || '',
         ip_address: IP || '',
         user_name: (user && user.adminName) || "",
         user_email : (user && user.email) || "",
       }
       if (isBeams() && type === 31 && deletedEmails && deletedLevel) {
        data.deleted_email = deletedEmails;
        data.deleted_level = deletedLevel;
      }

       if (isBeams(data.company_id) && type === 5 ) {
        data.approval_matrix = data.approval_matrix.map((ele)=>{
          delete ele.activeLevel
          delete ele.selectedEmail
          return ele
        })
      }
      if (isAWL() && type === 32 && deletedEmails && deletedLevel) {
        data.email = deletedEmails;
      }
       if(matrix && matrix.setting_id || setting_id) {
         data['setting_id'] = type === 1 ? setting_id : matrix.setting_id;
         this.props.updateApproverList(data);
       } else {
         this.props.postApproverList(data);
       }
      }
    }

    addApproverLevel = (index, type, subIndex) => {
      const { approver_values, approval_matrix, selApproverType } = this.state;
      if(!approval_matrix[index]["data_matrix"]) {
         approval_matrix[index]["data_matrix"] = [];
      }

      if (type===1) {
        if(approval_matrix[index]["data_matrix"].length===0) {
          approval_matrix[index]["data_matrix"].push({ approver_type: type, levels: [] });
        }
        if(approval_matrix[index]["data_matrix"][approval_matrix[index]["data_matrix"].length-1]) {
          const levelsLen = approval_matrix[index]["data_matrix"][(approval_matrix[index]["data_matrix"].length - 1)]['levels'];
          if((levelsLen.length > 0 && levelsLen[levelsLen.length - 1]['emails'].length > 0) || !levelsLen.length) {
            const data = {
              level: approval_matrix[index]["data_matrix"][approval_matrix[index]["data_matrix"].length-1].levels.length+1,
              emails: [],
            }
            approval_matrix[index]["data_matrix"][(approval_matrix[index]["data_matrix"].length - 1)]['levels'].push(data);
          }
        }
      } 
      else if([15, 16, 17, 18, 19, 20, 21, 24].includes(type) && subIndex !== undefined){
        if(!approval_matrix[index].data_matrix[subIndex]["data_matrix"]){
          approval_matrix[index].data_matrix[subIndex]["data_matrix"] = [];
        }
        const data = {
          level: approval_matrix[index].data_matrix[subIndex]["data_matrix"].length+1,
          emails: [],
          user_ids: [],
          department_id: '',
          department_name: '',
        }
        approval_matrix[index].data_matrix[subIndex].data_matrix.push(data);
      }
      else if(type == 32){
        const data = {
          level: approval_matrix[index]["data_matrix"].length+1,
          emails: [],
          user_ids: [],
          department_id: '',
          department_name: '',
          range_id: '',
          weighment_range: []
        }
        approval_matrix[index].data_matrix.push(data);
      }
      else {
        const data = {
          level: approval_matrix[index]["data_matrix"].length+1,
          emails: [],
          user_ids: [],
          department_id: '',
          department_name: '',
        }
        approval_matrix[index].data_matrix.push(data);
      }
      this.setState({ approval_matrix, approver_values: approval_matrix });
   }

  handleOnChangeForTo = (index,indDM,selectedOption={}) => {
    this.setState((prevState) => {
      const updateState = { ...prevState }
      let updateApprovalMatrix = prevState.approval_matrix.map((eleAM, indAM) => {
        if (index === indAM) {

          const newEle = {
            ...eleAM,
          }
          newEle.data_matrix = eleAM.data_matrix.map((eleDM,indOfDM)=>{
              if(indDM === indOfDM){
                const newEleDM = {
                  ...eleDM
                }
                newEleDM.to_code = selectedOption.region_code
               return newEleDM
              }else{
                return eleDM
              }
          })
        
          return newEle
        } else {
          return eleAM
        }
      })
      updateState.approval_matrix = updateApprovalMatrix;
      return updateState
    })
  }

  handleOnChangeForApproverType = (index,indDM,selectedOption={}) => {
    this.setState((prevState) => {
      const updateState = { ...prevState }
      let updateApprovalMatrix = prevState.approval_matrix.map((eleAM, indAM) => {
        if (index === indAM) {

          const newEle = {
            ...eleAM,
          }
          newEle.data_matrix = eleAM.data_matrix.map((eleDM,indOfDM)=>{
              if(indDM === indOfDM){
                const newEleDM = {
                  ...eleDM
                }
                newEleDM.is_csd = selectedOption.value
               return newEleDM
              }else{
                return eleDM
              }
          })
        
          return newEle
        } else {
          return eleAM
        }
      })
      updateState.approval_matrix = updateApprovalMatrix;
      return updateState
    })
  }

  handleOnChangeForLevel = (index,indDM,selectedOption={}) => {
    this.setState((prevState) => {
      const updateState = { ...prevState }
      let updateApprovalMatrix = prevState.approval_matrix.map((eleAM, indAM) => {
        if (index === indAM) {

          const newEle = {
            ...eleAM,
          }
          newEle.data_matrix = (eleAM.data_matrix||[]).map((eleDM,indOfDM)=>{
              if(indDM === indOfDM){
                const newEleDM = {
                  ...eleDM
                }
                newEleDM.activeLevel = selectedOption.value
               return newEleDM
              }else{
                return eleDM
              }
          })
        
          return newEle
        } else {
          return eleAM
        }
      })
      updateState.approval_matrix = updateApprovalMatrix;
      return updateState
    })
  }

  handleOnChangeForUserEmail = (index,indDM,selectedOption={}) => {
    this.setState((prevState) => {
      const updateState = { ...prevState }
      let updateApprovalMatrix = prevState.approval_matrix.map((eleAM, indAM) => {
        if (index === indAM) {

          const newEle = {
            ...eleAM,
          }
          newEle.data_matrix = (eleAM.data_matrix||[]).map((eleDM,indOfDM)=>{
              if(indDM === indOfDM){
                const newEleDM = {
                  ...eleDM
                }
                newEleDM.selectedEmail = selectedOption
               return newEleDM
              }else{
                return eleDM
              }
          })
        
          return newEle
        } else {
          return eleAM
        }
      })
      updateState.approval_matrix = updateApprovalMatrix;
      return updateState
    })
  }

  handleOnClickForAddLevel = (index, type,indDM) => {
    this.setState((prevState) => {
      const updateState = { ...prevState }
      let updateApprovalMatrix = prevState.approval_matrix.map((eleAM, indAM) => {
        if (index === indAM) {

          const newEle = {
            ...(eleAM || {}),
          }
          newEle.data_matrix = (eleAM.data_matrix||[]).map((eleDM,indOfDM)=>{
              if(indDM === indOfDM){
                const newEleDM = {
                  ...(eleDM ||{})
                }
                const C1 = !!eleDM.activeLevel
                const C2 = !!eleDM.selectedEmail
                if(C1 && C2){
                  newEleDM.levels = [...(eleDM.levels ||[])]
                  const indexOfLevel = newEleDM.levels.findIndex((ele)=>{
                    if(ele.level === eleDM.activeLevel){
                      return true
                    }else{
                      return false
                    }
                  })
                  if(indexOfLevel>=0){
                    const isEmailExists =  newEleDM.levels[indexOfLevel].emails.some((ele)=> ele === eleDM.selectedEmail.label )
                    if(!isEmailExists){
                      newEleDM.levels[indexOfLevel].emails = [
                        ...newEleDM.levels[indexOfLevel].emails,
                      eleDM.selectedEmail.label
                      ]
                    }
                  }else{
                    newEleDM.levels.push({
                      level:eleDM.activeLevel,
                      emails:[eleDM.selectedEmail.label]
                    })
                  }
                  
                }
               return newEleDM
              }else{
                return eleDM
              }
          })
        
          return newEle
        } else {
          return eleAM
        }
      })
      updateState.approval_matrix = updateApprovalMatrix;
      return updateState
    })
  }

  handleOnClickForAddBtn = (index, type) => {
    this.setState((prevState) => {
      const updateState = { ...prevState }
      let updateApprovalMatrix = prevState.approval_matrix.map((eleAM, indAM) => {
        if (index === indAM) {
          const from_code = this.props.plantsDepots.currentDepot
            ? this.props.plantsDepots.currentDepot.branch_code
            : "";
          const newDataMatrixIteam = {
            from_code: from_code,
            to_code: '',
            is_csd: false,
            levels: []
          }
          const newEle = {
            ...eleAM,
          }
          newEle.data_matrix = [...(eleAM.data_matrix ||[])];
          newEle.data_matrix.push(newDataMatrixIteam)
          return newEle
        } else {
          return eleAM
        }
      })
      updateState.approval_matrix = updateApprovalMatrix;
      return updateState
    })
  }

  deleteLOBLevel = (index, type, list) => {
    const { approval_matrix } = this.state;
    if (type == 24) {
      approval_matrix[index].data_matrix.pop()
      this.initializeSelectedCharges(list)
      this.setState({ approval_matrix })
    }
  }

   addLOBLevel = (index, type) => {
    const { approval_matrix } = this.state;
      
      if(!approval_matrix[index]["data_matrix"]) {
         approval_matrix[index]["data_matrix"] = [];
      }

      if([15, 16].includes(type)){
        const data = {
          id: '',
          name: '',
          lob: '',
          division: ''
        }
        approval_matrix[index].data_matrix.push(data);
      }
      else if(type === 19){
        const data = {
          destination: ''
        }
        approval_matrix[index].data_matrix.push(data);
      }
      else if(type === 24){
        const data = {
          charges:''
        }
        approval_matrix[index].data_matrix.push(data)
      }
      this.setState({ approval_matrix })
   }

   onSelectApprover = (option) => {
    const {match} = this.props
    const companyId = match.params.id
      let { approval_matrix } = this.state;
      let isApproverExist = false;
      if(!approval_matrix) {
         approval_matrix = []
      }

      (approval_matrix || []).forEach(apprverList => {
         if(apprverList.approver_type === option.value) {
            isApproverExist = true;
         }
      })

      if(!isApproverExist) {
       const C1 = isBeams(companyId)
       const C2 = option.value === 5
       if(C1 && C2) {
        const from_code = this.props.plantsDepots.currentDepot
        ? this.props.plantsDepots.currentDepot.branch_code
        : "";
         const newApprovalMatrixItems = {
           approver_type: option.value,
           data_matrix: [
             {
               from_code: from_code,
               to_code: '',
               is_csd: false,
               levels: []
             }
           ]
         }
         approval_matrix.push(newApprovalMatrixItems)
       }else{
         approval_matrix.push({
            approver_type : option.value
         })
       }
      }
      this.setState({ approval_matrix, selApproverType: option });
   }

  initializeSelectedCharges = (result) => {
    const { approval_matrix } = this.state;
    let selectedCharges = [];
    result && (result.data_matrix || []).forEach((data) => {
      if (data.charges && data.charges.length > 0) {
        selectedCharges = [...selectedCharges, ...data.charges.map(charge => charge.id)];
      }
    });
    this.setState({ selectedCharges: [...new Set(selectedCharges)] });
  }

   onSelectLOB = (option, mainIndex, type, subIndex) => {
      const { approval_matrix } = this.state;
      if([15, 16].includes(type)){
        let lobDetails = this.props.lobList.filter(obj => obj.name === option.label)
        approval_matrix[mainIndex].data_matrix[subIndex].name = option.label;
        approval_matrix[mainIndex].data_matrix[subIndex].id = option.value;
        approval_matrix[mainIndex].data_matrix[subIndex].division = lobDetails[0].division;
        approval_matrix[mainIndex].data_matrix[subIndex].lob = lobDetails[0].lob;
      } else if(type === 19){
        const data = [];
        (option || []).forEach((br) => {
          if(br.label){
            data.push(br.label);
          }
        }) 
        approval_matrix[mainIndex].data_matrix[subIndex].destination = data;
      } else if(type === 24){
        const charge = [];
        const selectedCharges = [];
        (option || []).forEach((op) => {
          if (op.label) {
            charge.push({ id: op.value, name: op.label });
            selectedCharges.push(op.value);
          }
        });
        let approval_matrix = [...this.state.approval_matrix];
        approval_matrix[mainIndex].data_matrix[subIndex].charges = charge;
        this.setState(prevState => ({
          approval_matrix,
          selectedCharges: [...new Set([...prevState.selectedCharges, ...selectedCharges])] // Merge and remove duplicates
        }));
      }
      this.setState({approval_matrix});
   }

   onSelectSlab = (option, mainIndex, subIndex, type, mails) => {
    const { approval_matrix } = this.state;
    if(!(approval_matrix && approval_matrix[mainIndex])) {
        approval_matrix[mainIndex] = [];
        approval_matrix[mainIndex]['data_matrix'] = [];

        const data = {
          approver_type: type,
          emails: mails ? mails : [],
          department_id: "",
          department_name: "",
          level: approval_matrix[mainIndex].data_matrix.length,
          range_id: option.value,
          weighment_range: [],
          user_ids: []
        }

        data['weighment_range'].push({upperlimit: option.upperlimit, lowerlimit: option.lowerlimit})

        approval_matrix[mainIndex].data_matrix.push(data);
      }
      if(!approval_matrix[mainIndex].data_matrix && !approval_matrix[mainIndex].data_matrix[subIndex]) {
        const item = { emails:mails, level: approval_matrix[mainIndex].data_matrix[subIndex] }

        item['range_id'] = approval_matrix[mainIndex].data_matrix[subIndex].range_id ? approval_matrix[mainIndex].data_matrix[subIndex].range_id : ''
        item['weighment_range'] = approval_matrix[mainIndex].data_matrix[subIndex].weighment_range ? approval_matrix[mainIndex].data_matrix[subIndex].weighment_range : [];
        
        approval_matrix[mainIndex].data_matrix.push(item)
      }

      if(approval_matrix[mainIndex].data_matrix[subIndex]){
        let range = approval_matrix[mainIndex].data_matrix[subIndex].weighment_range || [];
        range.push({upperlimit: option.upperlimit, lowerlimit: option.lowerlimit})
        approval_matrix[mainIndex].data_matrix[subIndex] = { 
          emails: mails, 
          level: approval_matrix[mainIndex].data_matrix.length,
          range_id: option.value,
          weighment_range: range,
          department_id: "",
          department_name: "",
          user_ids: approval_matrix[mainIndex].data_matrix[subIndex].user_ids || []
        }
      }
      this.setState({ approval_matrix, isSlabSelected: true, selectedSlab: [{label: option.label, value: option.value }]});
   }

   onSelectDept = (option, mainIndex, subIndex, type, mails) => {
      const { approval_matrix } = this.state;

      if(!(approval_matrix && approval_matrix[mainIndex])) {
        approval_matrix[mainIndex] = [];
        approval_matrix[mainIndex]['data_matrix'] = [];

        const data = {
          approver_type: type,
          emails: mails ? mails : [],
          department_id: option.value,
          department_name: option.label,
          level: approval_matrix[mainIndex].data_matrix.length
        }

        if (appType===3) {
          data['department_name'] = approval_matrix[mainIndex].data_matrix[subIndex].department_id ? approval_matrix[mainIndex].data_matrix[subIndex].department_id : option.value;
          data['department_id'] = approval_matrix[mainIndex].data_matrix[subIndex].department_name ? approval_matrix[mainIndex].data_matrix[subIndex].department_name : option.label;
        }

        approval_matrix[mainIndex].data_matrix.push(data);
      } 
      if(!approval_matrix[mainIndex].data_matrix && !approval_matrix[mainIndex].data_matrix[subIndex]) {
        const item = { emails:mails, level: approval_matrix[mainIndex].data_matrix[subIndex] }

        if (appType===3) {
          item['department_id'] = approval_matrix[mainIndex].data_matrix[subIndex].department_id ? approval_matrix[mainIndex].data_matrix[subIndex].department_id : ''
          item['department_name'] = approval_matrix[mainIndex].data_matrix[subIndex].department_name ? approval_matrix[mainIndex].data_matrix[subIndex].department_name : '';
        }
         
        approval_matrix[mainIndex].data_matrix.push(item)
      }

      if (approval_matrix[mainIndex].data_matrix[subIndex]) {
        approval_matrix[mainIndex].data_matrix[subIndex] = { 
          emails: mails, 
          department_id: option.value, 
          department_name: option.label, 
          level: approval_matrix[mainIndex].data_matrix.length
        };
      }
      this.setState({ deptEmails: option.emails || [] });
      this.setState({ approval_matrix });
   }


  onSelectEmail = (option, mainIndex, subIndex, levelIndex, appType, lobIndex) => {
    const { approval_matrix } = this.state;
    if (appType === 1) {
      if (!(approval_matrix && approval_matrix[mainIndex])) {
        approval_matrix[mainIndex]['data_matrix'] = [];
        if (!(approval_matrix && approval_matrix[mainIndex]['data_matrix'])) {
          approval_matrix[mainIndex]['data_matrix'] = [{ approver_type: appType, levels: [] }];
        }
      }
      if (!approval_matrix[mainIndex].data_matrix[subIndex]['levels']) {
        approval_matrix[mainIndex].data_matrix[subIndex]['levels'].push({
          emails: []
        });
      }
      if (option.length > 0) {
        (option || []).forEach((op) => {
          if (!approval_matrix[mainIndex].data_matrix[subIndex]['levels'][levelIndex]['emails'].includes(op.value)) {
            approval_matrix[mainIndex].data_matrix[subIndex]['levels'][levelIndex]['emails'].push(op.value);
          }
        });
      } else {
        approval_matrix[mainIndex].data_matrix[subIndex]['levels'][levelIndex]['emails'] = [];
      }
      this.setState({ approval_matrix, selectedSlab: {}, isSlabSelected: false });
    } 
    // else if (appType === 5) {
    //   if (!approval_matrix[mainIndex]) {
    //     approval_matrix[mainIndex] = {};
    //     approval_matrix[mainIndex].data_matrix = [];
    //   }
    //   if (!approval_matrix[mainIndex].data_matrix[subIndex]) {
    //     approval_matrix[mainIndex].data_matrix[subIndex] = {
    //       emails: [],
    //       user_ids: []
    //     };
    //   }
    //   if (option.length > 0) {
    //     // Only allow one option to be selected at a time
    //     const selectedOption = option[0]; // Take the first selected option
    //     approval_matrix[mainIndex].data_matrix[subIndex].emails = [selectedOption.label]; // Display email ID
    //     approval_matrix[mainIndex].data_matrix[subIndex].user_ids = [selectedOption.value];
    //   } else {
    //     // If no options are selected, reset the emails and user_ids arrays
    //     approval_matrix[mainIndex].data_matrix[subIndex].emails = [];
    //     approval_matrix[mainIndex].data_matrix[subIndex].user_ids = [];
    //   }
    //   this.setState({ approval_matrix });
    // } 
    else if (appType === 31) {
      if (!approval_matrix[mainIndex]) {
          approval_matrix[mainIndex] = {};
          approval_matrix[mainIndex].data_matrix = [];
      }
      if (!approval_matrix[mainIndex].data_matrix[subIndex]) {
          approval_matrix[mainIndex].data_matrix[subIndex] = {
              emails: [],
              user_ids: []
          };
      }
      if (option.length > 0) {
          // Allow multiple options to be selected and added as emails
          option.forEach((op) => {
              let emailId = op.value;
              let userId = null;
              if (op.label !== op.value) {
                  emailId = op.label;
                  userId = op.value;
              }
              if (!approval_matrix[mainIndex].data_matrix[subIndex].emails.includes(emailId)) {
                  approval_matrix[mainIndex].data_matrix[subIndex].emails.push(emailId);
                  if (userId) {
                      approval_matrix[mainIndex].data_matrix[subIndex].user_ids.push(userId);
                  }
              }
          });
      } else {
          // If no options are selected, reset the emails and user_ids arrays
          approval_matrix[mainIndex].data_matrix[subIndex].emails = [];
          approval_matrix[mainIndex].data_matrix[subIndex].user_ids = [];
      }
      this.setState({ approval_matrix, selectedSlab: {}, isSlabSelected: false });
  }
    else {
      if (!(approval_matrix && approval_matrix[mainIndex])) {
        approval_matrix[mainIndex] = [];
        approval_matrix[mainIndex]['data_matrix'] = [];
        const data = {
          approver_type: appType,
          emails: [],
          user_ids: []
        };
        if (appType === 3) {
          data['department_name'] = approval_matrix[mainIndex].data_matrix[subIndex].department_id ? approval_matrix[mainIndex].data_matrix[subIndex].department_id : '';
          data['department_id'] = approval_matrix[mainIndex].data_matrix[subIndex].department_name ? approval_matrix[mainIndex].data_matrix[subIndex].department_name : '';
        }

        approval_matrix[mainIndex].data_matrix.push(data);
      }
      if (!approval_matrix[mainIndex].data_matrix && !approval_matrix[mainIndex].data_matrix[subIndex]) {
        const item = {
          emails: [],
          user_ids: []
        };
        if (appType === 3) {
          item['department_id'] = approval_matrix[mainIndex].data_matrix[subIndex].department_id ? approval_matrix[mainIndex].data_matrix[subIndex].department_id : ''
          item['department_name'] = approval_matrix[mainIndex].data_matrix[subIndex].department_name ? approval_matrix[mainIndex].data_matrix[subIndex].department_name : '';
        }
        approval_matrix[mainIndex].data_matrix.push(item);
      }
      if (option.length > 0) {
        (option || []).forEach((op) => {
          if ([15, 16, 19, 24].includes(appType)) {
            if (op.label && !approval_matrix[mainIndex].data_matrix[subIndex].data_matrix[lobIndex].emails.includes(op.label)) {
              approval_matrix[mainIndex].data_matrix[subIndex].data_matrix[lobIndex].emails.push(op.label);
              approval_matrix[mainIndex].data_matrix[subIndex].data_matrix[lobIndex].user_ids.push(op.value);
            }
          } 
          // if([32].includes(appType)){
          //   if (op.label && !approval_matrix[mainIndex].data_matrix[subIndex].emails.includes(op.label)) {
          //     approval_matrix[mainIndex].data_matrix[subIndex].emails.push(op.label);
          //     approval_matrix[mainIndex].data_matrix[subIndex].user_ids.push(op.value);
          //   }
          // }
           else {
            let emailId = op.value;
            let userId = null;
            if (op.label !== op.value) {
              emailId = op.label;
              userId = op.value;
            }
            if (!approval_matrix[mainIndex].data_matrix[subIndex].emails.includes(emailId)) {
              approval_matrix[mainIndex].data_matrix[subIndex].emails.push(emailId);
              if (userId) {
                approval_matrix[mainIndex].data_matrix[subIndex].user_ids.push(userId);
              }
            }else{
              if(appType === 32 && approval_matrix[mainIndex].data_matrix[subIndex].weighment_range && approval_matrix[mainIndex].data_matrix[subIndex].emails.length !== approval_matrix[mainIndex].data_matrix[subIndex].weighment_range){
                let lastSlab = approval_matrix[mainIndex].data_matrix[subIndex].weighment_range.length-1;
                approval_matrix[mainIndex].data_matrix[subIndex].weighment_range.splice(lastSlab, 1)
              }
            }
          }
        });
      }
      this.setState({ approval_matrix, selectedSlab: {}, isSlabSelected: false });
    }
  }
  
 addLevelApprover = (mainIndex, subIndex, emailIndex, type) => {
      const { approver_values, approval_matrix, selApproverType } = this.state;

      if(type===1){
        if(approver_values[mainIndex].data_matrix[subIndex].levels[emailIndex].emails && 
          approver_values[mainIndex].data_matrix[subIndex].levels[emailIndex].emails.length > 0
        ){
          (approver_values[mainIndex].data_matrix[subIndex].levels[emailIndex].emails || []).forEach(eml => {
              if(approval_matrix[mainIndex].data_matrix[subIndex].levels[emailIndex].emails.indexOf(eml.value) === -1) {
                 approval_matrix[mainIndex].data_matrix[subIndex].levels[emailIndex].emails.push(eml.value);
              }
           })
           
           this.setState({
              approval_matrix
           }, () => {
               approver_values[mainIndex].data_matrix[subIndex].levels[emailIndex].emails= [];
               this.setState({ approver_values });
           })
        }
      } else {
        if(approval_matrix[mainIndex] && approval_matrix[mainIndex].data_matrix[subIndex] && approver_values[mainIndex] && approver_values[mainIndex].data_matrix[subIndex]){
        approval_matrix[mainIndex].data_matrix[subIndex].department_id = approver_values[mainIndex].data_matrix[subIndex].department_id;
        approval_matrix[mainIndex].data_matrix[subIndex].department_name = approver_values[mainIndex].data_matrix[subIndex].department_name;
        approval_matrix[mainIndex].data_matrix[subIndex].level = approval_matrix[mainIndex].data_matrix.length
        approval_matrix[mainIndex].data_matrix[subIndex].emails = approver_values[mainIndex].data_matrix[subIndex].emails

         // (approver_values[mainIndex].data_matrix[subIndex].emails || []).map(eml => {
         //    if(approval_matrix[mainIndex].data_matrix[subIndex].emails.indexOf(eml.value) === -1) {
         //       approval_matrix[mainIndex].data_matrix[subIndex].emails.push(eml.value);
         //    }
         // })
      }
         this.setState({
            approval_matrix
         }, () => {
             approver_values[mainIndex].data_matrix[subIndex].emails= [];
             if (approval_matrix[mainIndex].approver_type!==1) {
               approver_values[mainIndex].data_matrix[subIndex].department_id = '';
               approver_values[mainIndex].data_matrix[subIndex].department_name = '';
             }
             this.setState({ approver_values });
         })
        
      }
   }

   deleteLevelApprover = (levelIndex, subIndex, loiEmailIndex, subEmailIndex, type, lobIndex) => {
      const { approval_matrix } = this.state;
      let deletedEmails = null;
      let deletedLevel = null;
      if (type===1) {

        if( approval_matrix[levelIndex].data_matrix[subIndex].levels[loiEmailIndex] && approval_matrix[levelIndex].data_matrix[subIndex].levels[loiEmailIndex].emails ){
          approval_matrix[levelIndex].data_matrix[subIndex].levels[loiEmailIndex].emails.splice(subEmailIndex, 1);
          
          if (approval_matrix[levelIndex].data_matrix[subIndex].levels[loiEmailIndex].emails.length === 0) {
            approval_matrix[levelIndex].data_matrix[subIndex].levels.splice(loiEmailIndex, 1);
          }

          (approval_matrix[levelIndex].data_matrix[subIndex].levels || []).map((levelList, lIndex) => {
            levelList.level = (lIndex + 1)
          })
        }
        if(approval_matrix[levelIndex].data_matrix[subIndex].levels.length === 0) {
            approval_matrix.splice(levelIndex, 1)
        }

      } else if([15, 16, 19, 24].includes(type)){
        if( approval_matrix[levelIndex].data_matrix[subIndex].data_matrix[lobIndex] && approval_matrix[levelIndex].data_matrix[subIndex].data_matrix[lobIndex].emails ){
          approval_matrix[levelIndex].data_matrix[subIndex].data_matrix[lobIndex].emails.splice(subEmailIndex, 1);
          if (approval_matrix[levelIndex].data_matrix[subIndex].data_matrix[lobIndex].emails.length === 0) {
            approval_matrix[levelIndex].data_matrix[subIndex].data_matrix.splice(lobIndex, 1);
          }
          if( approval_matrix[levelIndex].data_matrix[subIndex].data_matrix[lobIndex] && approval_matrix[levelIndex].data_matrix[subIndex].data_matrix[lobIndex].user_ids ){
          approval_matrix[levelIndex].data_matrix[subIndex].data_matrix[lobIndex].user_ids.splice(subEmailIndex, 1);
          if (approval_matrix[levelIndex].data_matrix[subIndex].data_matrix[lobIndex].user_ids.length === 0) {
            approval_matrix[levelIndex].data_matrix[subIndex].data_matrix.splice(lobIndex, 1);
          }
        }
          (approval_matrix[levelIndex].data_matrix[subIndex].data_matrix || []).map((levelList, lIndex) => {
            levelList.level = (lIndex + 1)
          })
        }
        if(approval_matrix[levelIndex].data_matrix[subIndex].data_matrix.length === 0) {
           approval_matrix[levelIndex].data_matrix[subIndex].data_matrix.splice(subIndex, 1);
           
        }
       }else if (this.conditionsToStoreDeletedData(type)) {   
        if (
          approval_matrix[levelIndex].data_matrix[subIndex] &&
          approval_matrix[levelIndex].data_matrix[subIndex].emails
        ) {
          // Store the deleted data into a variable before deletion
          deletedEmails = approval_matrix[levelIndex].data_matrix[subIndex].emails[subEmailIndex];
          deletedLevel = approval_matrix[levelIndex].data_matrix[subIndex].level;

          approval_matrix[levelIndex].data_matrix[subIndex].emails.splice(
            subEmailIndex,
            1
          );
          try {
            approval_matrix[levelIndex].data_matrix[subIndex].user_ids.splice(
              subEmailIndex,
              1
            );
          } catch (error) {
            console.log('Missing user_ids in approval matrix: ', error);
          }
    
          if (approval_matrix[levelIndex].data_matrix[subIndex].emails.length === 0) {
            approval_matrix[levelIndex].data_matrix.splice(subIndex, 1);
          }
    
          (approval_matrix[levelIndex].data_matrix || []).map((levelList, lIndex) => {
            levelList.level = lIndex + 1;
          });
    
          // Now, deletedEmails contains the deleted email
          console.log('Deleted Data:', deletedEmails);
        }
      }
      else {
        if( approval_matrix[levelIndex].data_matrix[subIndex] && approval_matrix[levelIndex].data_matrix[subIndex].emails ){
          approval_matrix[levelIndex].data_matrix[subIndex].emails.splice(subEmailIndex, 1);
          try {
            approval_matrix[levelIndex].data_matrix[subIndex].user_ids.splice(subEmailIndex, 1);
          } catch (error) {
            console.log('Missing user_ids in approval matrix: ', error);
          }

          if (approval_matrix[levelIndex].data_matrix[subIndex].emails.length === 0) {
            approval_matrix[levelIndex].data_matrix.splice(subIndex, 1);
          }
          
          (approval_matrix[levelIndex].data_matrix || []).map((levelList, lIndex) => {
            levelList.level = (lIndex + 1)
          })
        }
        if( approval_matrix[levelIndex].data_matrix[subIndex] && approval_matrix[levelIndex].data_matrix[subIndex].weighment_range ){
          approval_matrix[levelIndex].data_matrix[subIndex].weighment_range.splice(subEmailIndex, 1);
        }
        if(approval_matrix[levelIndex].data_matrix.length === 0) {
           approval_matrix[levelIndex].data_matrix.splice(levelIndex, 1);
        }
        
      }

      this.setState({ approval_matrix }, () => {
        // Call updateApproverList only for given condition and pass the deletedData
        this.updateApproverList(type, deletedEmails,deletedLevel,levelIndex);
        // if (this.conditionsToSendDeletedDataToUpdateFunction(type)) {
        //   this.updateApproverList(type, deletedEmails,deletedLevel,levelIndex);
        // } else {
        //   this.updateApproverList(type, deletedEmails,deletedLevel,levelIndex);
        // }
      }); 
   }

   handleOnClickForDeleteLevel = (index,type,indDM,indLvl)=>{
     this.setState((prevState) => {
       const updateState = { ...prevState }
       let updateApprovalMatrix = prevState.approval_matrix.map((eleAM, indAM) => {
         if (index === indAM) {
           const newEle = {
             ...eleAM,
           }
           newEle.data_matrix = (eleAM.data_matrix||[]).map((eleDM, indOfDM) => {
             if (indDM === indOfDM) {
               const newEleDM = {
                 ...eleDM
               }
               newEleDM.levels = eleDM.levels.filter((_,indOfLvl)=>{
                 return indLvl !== indOfLvl
               })
               return newEleDM
             } else {
               return eleDM
             }
           })
           newEle.data_matrix =  newEle.data_matrix.filter((eleDM,indOfDM)=>{
            if(indDM === indOfDM && eleDM.levels.length === 0) {
              return false
            }else{
              return true
            }
           })
           return newEle
         } else {
           return eleAM
         }
       })
       updateState.approval_matrix = updateApprovalMatrix;
       return updateState
     })
   }

   conditionsToStoreDeletedData = (type) => {
    if(isBeams()){
      if(type === 31){
        return true;
      }
    }
    return false;
 }
   conditionsToSendDeletedDataToUpdateFunction = (type) => {
    if(isBeams()){
      if(type === 31){
        return true;
      }
    }
    return false;
 }

   clickCustomeCheckBoxService = () => {
      const { applyToOtherBranches } = this.state;
      this.setState({  applyToOtherBranches: !applyToOtherBranches });
   }

   applyBranches = (dataList) => {
      const { approverMatrix }  = this.props;
      const { company_id, branch_id } = this.state;
      const data = {
         setting_id: dataList.setting_id,
         branch_list: dataList.branch_list,
         companyId: company_id,
         branchId: branch_id
      }
      this.props.applyApproverToOtherBranches(data);
   }

   clickOnAddNewLine = (approverIndex,mainIndex, lobIndex, approver_type, can_add_new_charge) => {

    const {approval_matrix} = this.state;
    approval_matrix[approverIndex].data_matrix[mainIndex].data_matrix[lobIndex].can_add_new_charge = !can_add_new_charge

    this.setState({approval_matrix})
   }

  onToggleAccordion = (approverList) => {
    const { showSection, approval_matrix } = this.state;
    const sectionIndex = showSection.findIndex(el => el === approverList.approver_type)
    if (sectionIndex === -1) {
      showSection.push(approverList.approver_type)
    }
    else {
      showSection.splice(sectionIndex, 1)
    }
    if (approverList.approver_type == 24) {
      const result = approval_matrix.find(item => item.approver_type === 24) || {};
      this.initializeSelectedCharges(result);
    }
    this.setState({ showSection })
  }

  getLowerAndUpperLimit = (data, index) => {
    const P1 = data &&
      data.weighment_range &&
      Array.isArray(data.weighment_range) &&
      data.weighment_range.length > 0 &&
      data.weighment_range[index].lowerlimit || 0.0
    const P2 = data &&
      data.weighment_range &&
      Array.isArray(data.weighment_range) &&
      data.weighment_range.length > 0 &&
      data.weighment_range[index].upperlimit || 0.0
    return `${P1} To ${P2}` || 'To'
  }

    render() {
      const {match} = this.props
      const companyId = match.params.id
        const {errors, selectedCharges, emails , approval_matrix, selApproverType, approval_mode, approver_values, deptEmails, checkDropDown, showSection, emailIDs} = this.state;
        console.log("approval_matrix",approval_matrix)
        const filteredOptions = (this.props.allChargesOptions || []).filter(option =>
        !this.state.selectedCharges.includes(option.value)
      );
        const code = this.props.plantsDepots.currentDepot
        ? this.props.plantsDepots.currentDepot.branch_code
        : "";
        let lobListing = (this.props.lobList || []).map((lob) => {
          return {
            label: lob.name,
            value: lob.id
          }
        })
        let cityList = ((this.props.plantsDepots || {}).citiesList || []).map((city) => {
          return {
            label: city.city,
            value: city.city
          }
        })
        const csdApproverOptions = [
          { value: true, label: 'CSD' },
          { value: false, label: 'Non CSD' },
        ];
        const approverLevelOptions = [
          { value: 1, label: 'Level 1' },
          { value: 2, label: 'Level 2' },
          { value: 3, label: 'Level 3' },
          { value: 4, label: 'Level 4' },
        ];
        let slabOptions = (this.props.slabRange || []).map((slab) => {
          return {
            label: `${slab.lowerlimit} To ${slab.upperlimit}`,
            value: slab.range_id,
            lowerlimit: slab.lowerlimit,
            upperlimit: slab.upperlimit
          }
        })
        return (
            <div className="golden-box ">
                  <div className="select-approver dInBlock">
                    <Select 
                      placeholder="Select Approver Type" 
                      value={selApproverType}
                      onChange={this.onSelectApprover}
                      options={this.props.approverTypes || []} 
                    />
                  </div>
                  <div className="select-approver dInBlock">
                    <Select 
                      placeholder="Select Approval Mode" 
                      value={APPROVAL_MODE.find(obj => obj.value===approval_mode)}
                      onChange={(value) => { this.setState({ approval_mode: value.value }) }}
                      options={[{id: 1, label: 'Flat', value: 1}, {id: 2, label: 'Hierarchy', value: 2}]} 
                    />
                  </div>
                  {//Object.keys(selApproverType).length > 0 && 
                    (approval_matrix || []).length > 0 &&
                   (approval_matrix || []).map((approverList, apporverIndex) => {
                    const C1 = isBeams(companyId)
                    const C2 = [5].includes(approverList.approver_type)
                    const C3 = C1 && C2;

                    if (approverList.approver_type) {
                      return(
                      <div className={'golden-box golden-box-sub'}>
                       <div className={'heading sub-heading'}>
                        {approverList.approver_type && (this.props.approverTypes.find(list => list.value === approverList.approver_type) || {}).label || ''}
                        
                        <div className={showSection.includes(approverList.approver_type) ? "arrow-icon rotate" : "arrow-icon"} style={{ backgroundImage: `url(${Dropup})` }} 
                        onClick={() =>this.onToggleAccordion(approverList)}
                        >
                        </div>
                    
                       </div>


                       {C3 && showSection.includes(approverList.approver_type) && (approverList.data_matrix||[]).map((eleDM,indDM)=>{
                       return<Fragment key={indDM+eleDM.to_code}>
                       <div className='VAMT1'>
                         <div className='rowWrapper'>
                           <div>
                             <div>
                               From
                             </div>
                               <div>
                             {eleDM.from_code}
                              
                             </div>
                           </div>
                           <div>
                             <div>
                               To
                             </div>
                             <Select
                               placeholder="Select Region Type"
                               options={this.props.regionmaster}
                               getOptionLabel={option => option.description}
                               getOptionValue={option => option.region_code}
                               value={this.props.regionmaster.find((ele) => {
                                 return (
                                  eleDM.to_code === ele.region_code
                                 );
                               })}
                               onChange={(selectedOption) => {
                                this.handleOnChangeForTo(apporverIndex,indDM,selectedOption)
                               }}
                             />
                           </div>
                           <div>
                             <div>
                             Select Approver Type
                             </div>
                             <Select
                               placeholder="Select Approver Type"
                               options={csdApproverOptions}
                               value={csdApproverOptions.find((ele) => {
                                 return (
                                  eleDM.is_csd === ele.value
                                 );
                               })}
                               onChange={(selectedOption) => {
                                this.handleOnChangeForApproverType(apporverIndex,indDM,selectedOption)
                               }}
                             />
                           </div>
                         </div>
                         <div className='p-5'></div>
                         <div className='rowWrapper'>
                           <div>
                             <div>
                               Select Level
                             </div>
                             <Select
                        
                               placeholder="Select Level"
                               options={approverLevelOptions}
                               value={csdApproverOptions.find((ele) => {
                                 return (
                                  eleDM.activeLevel === ele.value
                                 );
                               })}
                               onChange={(selectedOption) => {
                                this.handleOnChangeForLevel(apporverIndex,indDM,selectedOption)
                               }}
                             />
                           </div>
                           <div >
                             <div>
                               Select User
                             </div>
                               <Select
                                 value={eleDM.selectedEmail}
                                 placeholder="Select User ID"
                                 onChange={(selectedOption) => {
                                  this.handleOnChangeForUserEmail(apporverIndex,indDM,selectedOption)
                                 }}
                                 options={emailIDs}
                               />
                           </div>
                           <div>
                             <div className='p-10'>
                             </div>
                             <button
                               className="add-button add-level "
                               onClick={() => {
                                 this.handleOnClickForAddLevel(apporverIndex, approverList.approver_type,indDM)
                               }}
                             >Add </button>
                           </div>
                         </div>
                         <div className='p-5'></div>
                         <div>
                           <div className="golden-box-display">
                             <div className="headers"><div className="labels">Level</div></div>
                             <div className="headers"><div className="labels">Approver Email</div></div>
                             <div className="headers"><div className="labels">Action</div></div>
                           </div>
                             {(eleDM.levels || []).map((eleLvl, indLvl) => {
                               return (
                                 <div className="golden-box-display">
                                   <div className="details"><div className="labels">{eleLvl.level}</div></div>
                                   <div className="details">
                                     {eleLvl.emails.map((eleEm,indEm) => {
                                       return <Fragment key={indEm+eleLvl.level}>
                                         <div className="labels">
                                            {eleEm}
                                         </div>
                                       </Fragment>
                                     })}
                                   </div>
                                   <div className="details">
                                     <div
                                       className="delete-icon"
                                       style={{ backgroundImage: `url(${Delete})` }}
                                       onClick={() => {
                                        this.handleOnClickForDeleteLevel(apporverIndex, approverList.approver_type,indDM,indLvl)
                                       }
                                       }
                                     >
                                     </div>
                                   </div>
                                 </div>
                               )
                             })}
                           </div>
                         <hr />
                       </div>
                      </Fragment>
                       })}
       
                       {!C3 && showSection.includes(approverList.approver_type) && (approverList.data_matrix || []).map((list, mIndex) => {               
                          return (
                            <React.Fragment>
                              {list.levels && (list.levels || []).length > 0 ?
                                (list.levels || []).map((emailLevel, emailInd) => {
                                  return(
                                  <div>
                                   <div className="level-header">{`Level ${emailInd + 1}`}</div>
                                   <div className="approval-row">
                                      <Select 
                                        isMulti={true}
                                        value={[]}
                                        // approver_values[apporverIndex] && approver_values[apporverIndex]['data_matrix'] &&
                                        // approver_values[apporverIndex]['data_matrix'][mIndex] && approver_values[apporverIndex]['data_matrix'][mIndex]['levels'] &&
                                        // approver_values[apporverIndex]['data_matrix'][mIndex]['levels'][emailInd] &&
                                        // approver_values[apporverIndex]['data_matrix'][mIndex]['levels'][emailInd].emails ?
                                        // approver_values[apporverIndex]['data_matrix'][mIndex]['levels'][emailInd].emails.map(obj => { return {label: obj, value: obj} })
                                        // : []}
                                        placeholder="Select Email"
                                        onChange={(option) => this.onSelectEmail(option, apporverIndex, mIndex, emailInd, approverList.approver_type)}
                                        options={(emailLevel.emails || []).length > 0 && (emails || []).filter(eml => !((emailLevel.emails).includes(eml.value))) || emails}
                                        className="select"
                                      />
                                      {/*<div className="add-button" onClick={() => this.addLevelApprover(apporverIndex, mIndex, emailInd, approverList.approver_type)}>Add</div>*/}
                                   </div>
                                    <div className="golden-box-display">
                                      {approverList.approver_type===3 && <div className="headers"><div className="labels">Department</div></div>}
                                      <div className="headers"><div className="labels">Approver Email</div></div>
                                      <div className="headers"><div className="labels">Action</div></div>
                                   </div>
                                   {(emailLevel.emails || []).map((element, eleInd) => {
                                    return (
                                      <div className="golden-box-display">
                                        {approverList.approver_type===3 && <div className="labels">{emailLevel.department_name}</div>}
                                        <div className="details"><div className="labels">{element}</div></div>
                                        <div className="details"><div className = "delete-icon" style={{backgroundImage: `url(${Delete})`}} onClick = {() => this.deleteLevelApprover(apporverIndex, mIndex, emailInd, eleInd, approverList.approver_type)}></div></div>
                                      </div>
                                    )
                                   })}
                                  </div>)})
                                :
                                <React.Fragment>
                                  <div className="level-header" style={{backgroundColor: '#f0f3f7'}}>{approverList.approver_type===15 ? `LOB` : (approverList.approver_type === 24 ? `Charges ${mIndex + 1}` : (approverList.approver_type===19 ? 'Destination' : `Level ${mIndex + 1}`))}</div>
                                   {approverList.approver_type===3 && <div className="approval-row">
                                      <Select 
                                        value={list && list.department_name ? (this.state.depts || []).find(obj => obj.department_name === list.department_name) : {}}
                                         // value={approver_values[apporverIndex] && approver_values[apporverIndex].department_id ? (this.state.depts || []).find(obj => obj.department_id === approver_values[apporverIndex].department_id) : {}}
                                         onChange={(option) => this.onSelectDept(option, apporverIndex, mIndex, approverList.approver_type, list.emails)}
                                         placeholder="Select Department"
                                         options={this.state.depts} 
                                         className="select"
                                      />
                                   </div>}
                                   {[32].includes(approverList.approver_type) &&
                                   <div className="approval-row">
                                        <Select 
                                        value={this.state.selectedSlab}
                                        placeholder="Select Slab Range"
                                        onChange={(option) => this.onSelectSlab(option, apporverIndex, mIndex, approverList.approver_type, list.emails)}
                                        options={slabOptions} 
                                        className="select"
                                      />
                                    </div>}

                                     
                                     
                                   {approverList.approver_type===15 &&
                                   <>
                                    <div className="approval-row">
                                      <Select 
                                         value={list && list.name ? (lobListing || []).find(obj => obj.label === list.name) : {}}
                                         onChange={(option) => this.onSelectLOB(option, apporverIndex, approverList.approver_type, mIndex)}
                                         placeholder="Select LOB"
                                         options={lobListing} 
                                         className="select"
                                      />
                                   </div>
                                   <div className="add-button add-level" onClick={() => this.addApproverLevel(apporverIndex, approverList.approver_type, mIndex)}>Add Level</div>
                                   </>}
                                   {approverList.approver_type===24 &&
                                   <>
                                    <div className="approval-row">
                                      <Select 
                                        isMulti={true} 
                                        //  value={list && list.charges && list.charges.length > 0 ? (this.props.allChargesOptions || []).filter(obj => list.charges.find(c => c.id === obj.value) ) : undefined}
                                        value={list && list.charges && list.charges.length > 0 ? (this.props.allChargesOptions || []).filter(obj => list.charges.find(c => c.id === obj.value)) : undefined}
                                        onChange={(option) => this.onSelectLOB(option, apporverIndex, approverList.approver_type, mIndex)}
                                         placeholder="Select Charge"
                                        //  options={this.props.allChargesOptions} 
                                        options={filteredOptions}
                                         className="select"
                                      />
                                   </div>
                                   <div className="add-button add-level" onClick={() => this.addApproverLevel(apporverIndex, approverList.approver_type, mIndex)}>Add Level</div>
                                   </>}
                                   {approverList.approver_type===19 &&
                                   <>
                                    <div className="approval-row">
                                      <Select 
                                        isMulti={true} 
                                        className="select" 
                                        value={list && list.destination && list.destination.length > 0 ? (cityList || []).filter(obj => list.destination.includes(obj.label)) : {}} 
                                        onChange={(option) => this.onSelectLOB(option, apporverIndex, approverList.approver_type, mIndex)}
                                        options={cityList} 
                                        onInputChange={(value) => {
                                          this.setState({ citySearch: value })
                                          if (value.length > 1) {
                                              this.getCitiesList(value)
                                          }
                                      }}
                                      />
                                   </div>
                                   <div className="add-button add-level" onClick={() => this.addApproverLevel(apporverIndex, approverList.approver_type, mIndex)}>Add Level</div>
                                   </>}
                                   {[15, 16, 19, 24].includes(approverList.approver_type) ?
                                   <>
                                   {list && ((approverList.approver_type === 15 || approverList.approver_type === 16 || approverList.approver_type === 24) ? (approverList.approver_type === 24 ? list.charges : list.name) : list.destination) && list.data_matrix && list.data_matrix.length > 0 &&
                                   (list.data_matrix || []).map((lob_list, lobIndex) => {
                                    return (
                                      <>
                                      <div className="level-header" style={{borderBottom: 'none'}}>{`Level ${lobIndex + 1}`}</div>
                                      <div className="approval-row" style={{display:'flex'}}>
                                        <Select 
                                            isMulti={true} 
                                            value={lob_list.emails || null }
                                            placeholder="Select Email"
                                            onChange={(option) => this.onSelectEmail(option, apporverIndex, mIndex, null, approverList.approver_type, lobIndex)}
                                            options={emailIDs} 
                                            className="select"
                                            onInputChange={(value) => {
                                              this.setState({ emailSearch: value })
                                              if (value.length > 1) {
                                                  this.getEmailList(value)
                                              }
                                          }}
                                        />
                                          {approverList.approver_type !== 24 &&
                                          <div style={{ display: 'flex', marginTop: '10px' }}>
                                            Can add new line items
                                            <CustomCheckBox toggle={() => this.clickOnAddNewLine (apporverIndex, mIndex, lobIndex, approverList.approver_type, lob_list.can_add_new_charge)} selected={lob_list.can_add_new_charge} style={{ marginLeft: "5px" }} />
                                          </div>}

                                          {approverList.approver_type !== 24 && 
                                          <div style={{display:'flex', flexDirection:'column', marginLeft:'20px', marginTop:'10px'}}>
                                            <div>Auto approve after</div>
                                            <InputText
                                              type="number"
                                              placeholder="hours"
                                              value={lob_list.auto_approve_after ? lob_list.auto_approve_after/3600000 : null}
                                              changeHandler={(value) => {
                                                const valueInMilisecond = value * 3600 * 1000;
                                                approval_matrix[apporverIndex].data_matrix[mIndex].data_matrix[lobIndex].auto_approve_after = parseInt(valueInMilisecond)
                                                this.setState({approval_matrix})
                                              }}
                                            />
                                          </div>}
                                          {/* <div className="add-button" onClick={() => this.addLevelApprover(apporverIndex, mIndex, null, approverList.approver_type)}>Add</div> */}
                                        </div>
                                      <div className="golden-box-display">
                                        <div className="headers"><div className="labels">Approver Email</div></div>
                                        <div className="headers"><div className="labels">Action</div></div>
                                      </div>
                                      {(list && list.data_matrix && list.data_matrix[lobIndex].emails || []).map((element, eleInd) => {
                                        return (
                                            <div className="golden-box-display">
                                                <div className="details"><div className="labels">{element}</div></div>
                                                <div className="details"><div className = "delete-icon" style={{backgroundImage: `url(${Delete})`}} onClick = {() => this.deleteLevelApprover(apporverIndex, mIndex, null, eleInd, approverList.approver_type, lobIndex)}></div></div>
                                            </div>
                                          )
                                      })}
                                    </>
                                    )
                                   })}
                                   </> 
                                   :
                                  <>
                                    <div className="approval-row">
                                      {[17, 18, 20, 21].includes(approverList.approver_type) ?
                                      <Select 
                                        isMulti={true} 
                                        value={approver_values[apporverIndex] && approver_values[apporverIndex].emails || [] }
                                        placeholder="Select Email"
                                        onChange={(option) => this.onSelectEmail(option, apporverIndex, mIndex, null, approverList.approver_type)}
                                        options={emailIDs} 
                                        className="select"
                                        onInputChange={(value) => {
                                          this.setState({ emailSearch: value })
                                          if (value.length > 1) {
                                              this.getEmailList(value)
                                          }
                                      }}
                                    /> :
                                    <div>
                                      {(!isBeams(companyId)&& [5].includes(approverList.approver_type))?
                                      <Select 
                                         isMulti={true} 
                                         value={approver_values[apporverIndex] && approver_values[apporverIndex].emails || [] }
                                         placeholder="Select Email"
                                         onChange={(option) => this.onSelectEmail(option, apporverIndex, mIndex, null, approverList.approver_type)}
                                         options={ approverList.approver_type===3 ? (deptEmails || []).map(obj => { return { label: obj, value: obj } }) : emails} 
                                         className="select"
                                      /> :
                                      <Select 
                                        isMulti={true} 
                                        value={approver_values[apporverIndex] && approver_values[apporverIndex].emails || [] }
                                        placeholder="Select User ID"
                                        onChange={(option) => this.onSelectEmail(option, apporverIndex, mIndex, null, approverList.approver_type)}
                                        options={emailIDs} 
                                        className="select"
                                        isDisabled={[32].includes(approverList.approver_type) && !this.state.isSlabSelected}
                                      />}

                                      {isAWL() && [29].includes(approverList.approver_type) &&
                                    <span>
                                      <InputText 
                                            type="number"
                                            label="From"
                                            placeholder="From"
                                             value={approval_matrix[apporverIndex].data_matrix[mIndex].recheck_from} 
                                             changeHandler={(value) => {
                                              approval_matrix[apporverIndex].data_matrix[mIndex].recheck_from = parseInt(value)
                                              this.setState({ approval_matrix })
                                            }}
                                        />
                                        <InputText 
                                            type="number"
                                            label="To"
                                            placeholder="To"
                                            value={approval_matrix[apporverIndex].data_matrix[mIndex].recheck_to} 
                                            changeHandler={(value) => {
                                              approval_matrix[apporverIndex].data_matrix[mIndex].recheck_to = parseInt(value)
                                              this.setState({ approval_matrix })
                                            }}
                                        />
                                    </span>}
                                    
                                    {/* {isBeams(companyId) && [5].includes(approverList.approver_type) &&
                                  <span className='VAM'>
                                             <div>
                                                from
                                                <div>
                                                {code}
                                                </div>
                                              </div>
                                              
                                            <div>
                                              To
                                              <Select
                                                  className='wt-200'
                                                  placeholder="Select Region Type"
                                                  options={this.props.regionmaster}
                                                  getOptionLabel={option => option.description}
                                                  getOptionValue={option => option.region_code}
                                                  value={this.props.regionmaster.find((ele)=>{
                                                    return(
                                                    approval_matrix[apporverIndex].data_matrix[mIndex].dest_region === ele.dest_region
                                                    );
                                                  })}
                                                  onChange={(selectedOption) => {
                                                    approval_matrix[apporverIndex].data_matrix[mIndex].dest_region = selectedOption.region_code;
                                                    this.setState({ approval_matrix, dest_region: selectedOption });
                                                  }}

                                              />
                                            </div>
                                              
                                              <Select
                                                  className='wt-200'
                                                  placeholder="Select Approver Type"
                                                  options={csdApproverOptions}
                                                  value={csdApproverOptions.find((ele)=>{
                                                    return(
                                                    approval_matrix[apporverIndex].data_matrix[mIndex].isCsd === ele.isCsd
                                                    );
                                                  })}
                                                  onChange={(selectedOption) => {
                                                    approval_matrix[apporverIndex].data_matrix[mIndex].isCsd = selectedOption.value;
                                                    this.setState({ approval_matrix,isCsd: selectedOption });
                                                  }}
                                                />
                                    </span>} */}
                                      </div>
                                      }

                                      {!(isBeams(companyId)&&[5].includes(approverList.approver_type))&&<div className="add-button" onClick={() => this.addLevelApprover(apporverIndex, mIndex, null, approverList.approver_type)}>Add</div>}
                                        {
                                          [21].includes(approverList.approver_type) &&
                                          <div style={{ display: 'flex', flexDirection: 'column', marginLeft: '20px', marginTop: '10px' }}>
                                            <div>Max approval limit</div>
                                            <InputText
                                              type="number"
                                              placeholder="amount"
                                              value={list.max_price ? list.max_price : null}
                                              changeHandler={(value) => {
                                                approval_matrix[apporverIndex].data_matrix[mIndex].max_price = parseInt(value)
                                                this.setState({ approval_matrix })
                                              }}
                                            />
                                          </div>
                                        }
                                   </div>
                                    <div className="golden-box-display">
                                      {approverList.approver_type===3 && <div className="headers"><div className="labels">Department</div></div>}
                                      <div className="headers"><div className="labels">Approver Email</div></div>
                                      {approverList.approver_type===32 && <div className="headers"><div className="labels">Weight Range</div></div>}
                                      <div className="headers"><div className="labels">Action</div></div>
                                   </div>
                                   </>}
                                   {(list.emails || []).map((element, eleInd) => {
                                     return (
                                       <div className="golden-box-display">
                                         {approverList.approver_type === 3 && (
                                           <div className="labels">
                                             {list.department_name}
                                           </div>
                                         )}
                                         <div className="details">
                                           <div className="labels">
                                             {element}
                                           </div>
                                         </div>
                                         {approverList.approver_type === 32 && (
                                           <div className="labels">
                                            {
                                              this.getLowerAndUpperLimit(list, eleInd)
                                            }
                                            </div>
                                         )}
                                         <div className="details">
                                           <div
                                             className="delete-icon"
                                             style={{
                                               backgroundImage: `url(${Delete})`,
                                             }}
                                             onClick={() =>
                                               this.deleteLevelApprover(
                                                 apporverIndex,
                                                 mIndex,
                                                 null,
                                                 eleInd,
                                                 approverList.approver_type
                                               )
                                             }
                                           ></div>
                                         </div>
                                       </div>
                                     );
                                   })} 
                                </React.Fragment>
                              }
                            </React.Fragment>
                          )
                        })
                       }
                       
                       {showSection.includes(approverList.approver_type) &&
                        <div className="approver-matrix tCenter" style={{alignItems:"center"}}>
                          {approverList.approver_type === 24 && <p style={{color:"red"}}>{this.state.accountsChargeError}</p>}
                          {approverList.approver_type && [15, 16].includes(approverList.approver_type) ?
                          <div className="add-button add-level" onClick={() => this.addLOBLevel(apporverIndex, approverList.approver_type)}>Add LOB</div> :
                          (approverList.approver_type === 19 ? 
                          <div className="add-button add-level" onClick={() => this.addLOBLevel(apporverIndex, approverList.approver_type)}>Add Destination</div>
                          :
                                  ((approverList.approver_type === 24)
                                    ? (<div><div className="add-button add-level" onClick={() => this.addLOBLevel(apporverIndex, approverList.approver_type)}>Add Charge</div>
                                     <div className="add-button add-level" onClick={() => this.deleteLOBLevel(apporverIndex, approverList.approver_type, approverList)}>Delete Charge</div></div>)
                                    : isBeams(companyId) && [5].includes(approverList.approver_type) ? null
                                      : <div className="add-button add-level"
                                        onClick={() => this.addApproverLevel(apporverIndex, approverList.approver_type)}
                                      >Add Levels</div>
                                  ))
                          }

                              {isBeams(companyId) && [5].includes(approverList.approver_type) &&
                                <Fragment>
                                  <div
                                    className="add-button add-level"
                                    onClick={() => {
                                      this.handleOnClickForAddBtn(apporverIndex,approverList.approver_type)
                                    }}
                                  >
                                    Add
                                  </div>
                                </Fragment>
                              }
                          {approval_matrix.length > 0 && 
                          //  <div className="button-holder save">
                           <div className="add-button add-level" onClick={() => { this.updateApproverList(approverList.approver_type, "","",apporverIndex) }}>SAVE</div>
                        // </div>
                        }
                        </div>
                    }
                  </div>)
                    }
                    return null
                  })} 

                  {
                    // approval_matrix.length > 0 && <div className="button-holder save">
                    //  <div className="add-button" onClick={this.updateApproverList}>SAVE</div>
                    // </div>
                  }

                  {/* {approval_matrix.length > 0 && this.props.approverMatrix && Object.keys(this.props.approverMatrix).length > 0 &&
                   <div className="apply-settings">
                     <CustomCheckBox toggle={this.clickCustomeCheckBoxService} selected={this.state.applyToOtherBranches} />
                     <div className="label">Apply These Settings To Other Plants/Depots As Well</div>
                  </div>} */}
                  {this.state.applyToOtherBranches && <SelectBranches company_id={this.state.company_id} setting_id={this.props.approverMatrix.setting_id} toggle={this.clickCustomeCheckBoxService} applyBranches={this.applyBranches} branchList={this.props.branchList} getBranches={this.props.getBranches} />}  
               </div> 
        )
    } 
}

const CustomCheckBox = (props) => {
   return (
      <div className={props.selected ? "custome-checkbox-wrap selected" : "custome-checkbox-wrap"} onClick={() => props.toggle()} style ={props.style}>
         {props.selected && <div className="inner-circle"></div>}
      </div>
   )
}

export default connect()(withRouter((ApprovalMatrix)));
